var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "注册用户",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                disabled: "",
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "95px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "企业名称:", prop: "dealer_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.dealer_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealer_name", $$v)
                      },
                      expression: "form.dealer_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "ID:", prop: "dealer_id" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.dealer_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dealer_id", $$v)
                      },
                      expression: "form.dealer_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人:", prop: "boss" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.boss,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "boss", $$v)
                      },
                      expression: "form.boss",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机:", prop: "mobile" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话:", prop: "phone" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规模:", prop: "guimo" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", placeholder: "规模" },
                      model: {
                        value: _vm.form.guimo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "guimo", $$v)
                        },
                        expression: "form.guimo",
                      },
                    },
                    _vm._l(_vm.guimoList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "机构类型:", prop: "leixing" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", placeholder: "机构类型" },
                      model: {
                        value: _vm.form.leixing,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "leixing", $$v)
                        },
                        expression: "form.leixing",
                      },
                    },
                    _vm._l(_vm.leixingList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "行业:", prop: "hangye" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        clearable: "",
                        placeholder: "行业",
                        multiple: "",
                      },
                      model: {
                        value: _vm.form.hangye,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hangye", $$v)
                        },
                        expression: "form.hangye",
                      },
                    },
                    _vm._l(_vm.hangyeList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "所在地:", prop: "local" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px", "margin-right": "15px" },
                      attrs: { clearable: "", placeholder: "省" },
                      model: {
                        value: _vm.form.province,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "province", $$v)
                        },
                        expression: "form.province",
                      },
                    },
                    _vm._l(_vm.shengList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.title },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px", "margin-right": "15px" },
                      attrs: { clearable: "", placeholder: "市" },
                      model: {
                        value: _vm.form.city,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "city", $$v)
                        },
                        expression: "form.city",
                      },
                    },
                    _vm._l(_vm.shiList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.title },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100px", "margin-right": "15px" },
                      attrs: { clearable: "", placeholder: "区" },
                      model: {
                        value: _vm.form.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "area", $$v)
                        },
                        expression: "form.area",
                      },
                    },
                    _vm._l(_vm.quList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.title },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址:", prop: "address" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "504px" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "来源:", prop: "from" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { clearable: "", placeholder: "来源" },
                      model: {
                        value: _vm.form.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "from", $$v)
                        },
                        expression: "form.from",
                      },
                    },
                    _vm._l(_vm.fromList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推荐人:", prop: "parent_name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.parent_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parent_name", $$v)
                      },
                      expression: "form.parent_name",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "开通使用:", prop: "is_close" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": "0",
                      "inactive-value": "1",
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.form.is_close,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "is_close", $$v)
                      },
                      expression: "form.is_close",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "归属:", prop: "vest_in" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { clearable: "", placeholder: "归属" },
                      model: {
                        value: _vm.form.vest_in,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vest_in", $$v)
                        },
                        expression: "form.vest_in",
                      },
                    },
                    _vm._l(_vm.vestList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { value: i.id, label: i.username },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "管理员账号:", prop: "login_user" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.login_user,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "login_user", $$v)
                      },
                      expression: "form.login_user",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码:", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "开始日期:", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      placeholder: "开始日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "start_time", $$v)
                      },
                      expression: "form.start_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "结束日期:", prop: "end_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      placeholder: "结束日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "end_time", $$v)
                      },
                      expression: "form.end_time",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "端口上限数:", prop: "account_num" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.account_num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "account_num", $$v)
                      },
                      expression: "form.account_num",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "504px" },
                    attrs: { placeholder: "备注" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px", "text-align": "right" } },
        [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }