var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "keyword" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { placeholder: "企业ID或名称" },
                        model: {
                          value: _vm.form.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "keyword", $$v)
                          },
                          expression: "form.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { placeholder: "联系姓名或手机号" },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "area_id" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          filterable: "",
                          placeholder: "地区",
                          options: _vm.area,
                          props: {
                            checkStrictly: true,
                            emitPath: false,
                            value: "id",
                            label: "title",
                          },
                          clearable: "",
                        },
                        model: {
                          value: _vm.form.area_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "area_id", $$v)
                          },
                          expression: "form.area_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "vest_in" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: "归属" },
                          model: {
                            value: _vm.form.vest_in,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vest_in", $$v)
                            },
                            expression: "form.vest_in",
                          },
                        },
                        _vm._l(_vm.vest, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.truename },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "parent_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "150px" },
                        attrs: { placeholder: "推荐人姓名" },
                        model: {
                          value: _vm.form.parent_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parent_name", $$v)
                          },
                          expression: "form.parent_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "from" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: "来源" },
                          model: {
                            value: _vm.form.from,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "from", $$v)
                            },
                            expression: "form.from",
                          },
                        },
                        _vm._l(_vm.from, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: "状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.status, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { value: list.id, label: list.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerInquire },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerchognzhi },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: { click: _vm.createusers },
                        },
                        [_vm._v("创建")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c("el-button", { on: { click: _vm.handleExport } }, [
                        _vm._v("导出"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.tableData } },
                [
                  _vm._l(_vm.colemd, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          list.label == "状态"
                            ? {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.is_close == 0,
                                            expression: "row.is_close == 0",
                                          },
                                        ],
                                      },
                                      [_vm._v("试用")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.is_close == 1,
                                            expression: "row.is_close == 1",
                                          },
                                        ],
                                      },
                                      [_vm._v("封存")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: row.is_close == 2,
                                            expression: "row.is_close == 2",
                                          },
                                        ],
                                      },
                                      [_vm._v("待审核")]
                                    ),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center", width: "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            row.is_close == 0
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCustDetail(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 客情 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerlogin(
                                              _vm.$index,
                                              row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 登陆 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleredit(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleAccount(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 账密 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlerstaff(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 员工 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSigning(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 签约 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleStaffStop(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 封存 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCheck(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看 ")]
                                    ),
                                  ],
                                  1
                                )
                              : row.is_close == 1
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCheck(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_vm._v("启动")]
                                    ),
                                  ],
                                  1
                                )
                              : row.is_close == 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handlershenhe(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 审核 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleredit(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 编辑 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleCheck(row)
                                          },
                                        },
                                      },
                                      [_vm._v(" 查看 ")]
                                    ),
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              : _c("span"),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("add-users", { ref: "users", on: { getlist: _vm.getlist } }),
      _c("staff-user", { ref: "staff" }),
      _c("cust-detail", { ref: "custDetail" }),
      _c("account", { ref: "account", on: { refresh: _vm.handlerlist } }),
      _c("signing", { ref: "signing", on: { refresh: _vm.handlerlist } }),
      _c("check", { ref: "check" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }