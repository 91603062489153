var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "签约正式用户",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "企业名称:", prop: "dealer_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.topForm.dealer_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.topForm, "dealer_name", $$v)
                      },
                      expression: "topForm.dealer_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "ID:", prop: "dealer_id" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.topForm.dealer_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.topForm, "dealer_id", $$v)
                      },
                      expression: "topForm.dealer_id",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "开始日期:", prop: "start_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      placeholder: "开通日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.topForm.start_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.topForm, "start_time", $$v)
                      },
                      expression: "topForm.start_time",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "到期日期:", prop: "end_time" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "date",
                      placeholder: "结束日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.topForm.end_time,
                      callback: function ($$v) {
                        _vm.$set(_vm.topForm, "end_time", $$v)
                      },
                      expression: "topForm.end_time",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型1")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("开通数量")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("赠送数量")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("试用上限")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("开通端口")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_add", $$v)
                            },
                            expression: "form.port_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_gift", $$v)
                            },
                            expression: "form.port_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.port_test,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "port_test", $$v)
                            },
                            expression: "form.port_test",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型2")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("时长(月)")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("赠送(月)")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("签约时长")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_add,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_add", $$v)
                            },
                            expression: "form.time_add",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_gift,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_gift", $$v)
                            },
                            expression: "form.time_gift",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.time_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "time_money", $$v)
                            },
                            expression: "form.time_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("类型3")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("网销开关")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("门店上限")]),
                  _c("el-col", { attrs: { span: 6 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("网销")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-switch", {
                          attrs: {
                            "active-value": 1,
                            "inactive-value": 2,
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: _vm.form.mall_falg,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_falg", $$v)
                            },
                            expression: "form.mall_falg",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.mall_limit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_limit", $$v)
                            },
                            expression: "form.mall_limit",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.mall_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "mall_money", $$v)
                            },
                            expression: "form.mall_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table", staticStyle: { "text-align": "center" } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "line-height": "35px",
                    color: "'#606266'",
                    "background-color": "#f5f7fa",
                  },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [_vm._v("类型4")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训日期")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("培训人")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("天数")]),
                  _c("el-col", { attrs: { span: 5 } }, [_vm._v("金额")]),
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { gutter: 20 },
                },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [_vm._v("培训")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "grid-content",
                        staticStyle: { "line-height": "35px" },
                      },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            type: "date",
                            placeholder: "选择日期",
                            format: "yyyy 年 MM 月 dd 日",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.form.teach_date,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_date", $$v)
                            },
                            expression: "form.teach_date",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { clearable: "", placeholder: "培训人" },
                            model: {
                              value: _vm.form.teach_user,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "teach_user", $$v)
                              },
                              expression: "form.teach_user",
                            },
                          },
                          _vm._l(_vm.peixunrenList, function (i, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { value: i.id, label: i.username },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.teach_day,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_day", $$v)
                            },
                            expression: "form.teach_day",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 5 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.form.teach_money,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "teach_money", $$v)
                            },
                            expression: "form.teach_money",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px", "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }