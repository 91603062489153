<!--
 * @Author: 候怀烨
 * @Date: 2021-05-07 11:18:42
 * @LastEditTime: 2021-05-12 16:31:08
 * @LastEditors: Please set LastEditors
 * @Description: 创建注册用户
 * @FilePath: \sd-vue-admin\src\views\project\admin\userManagement\registeredUser\components\AddUsers.vue
-->
<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      :title="title"
      :visible.sync="showusers"
      append-to-body
      :close-on-click-modal="false"
      width="900px"
    >
      <el-divider></el-divider>
      <div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          inline
          label-width="120px"
        >
          <el-form-item label="企业名称" prop="dealer_name">
            <el-input
              v-model="form.dealer_name"
              placeholder="企业名称"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="ID">
            <el-input
              v-model="ID"
              disabled
              placeholder="ID"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="boss">
            <el-input
              v-model="form.boss"
              placeholder="联系人"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="手机"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="电话"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="规模">
            <el-select
              v-model="form.guimo"
              placeholder="规模"
              style="width: 200px"
            >
              <el-option
                v-for="list in guimolist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="机构类型">
            <el-select
              v-model="form.leixing"
              placeholder="机构类型"
              style="width: 200px"
            >
              <el-option
                v-for="list in leixinglist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="行业">
            <el-select
              v-model="form.hangye"
              placeholder="行业"
              style="width: 200px"
              multiple
            >
              <el-option
                v-for="list in hangyelist"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在地" prop="province">
            <el-select
              v-model="form.province"
              placeholder="省"
              style="width: 120px"
              @change="handlerprovince"
            >
              <el-option
                v-for="list in provincelist"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.city"
              placeholder="市"
              style="width: 120px; margin: 0px 20px"
              @change="handlercity"
            >
              <el-option
                v-for="list in citylist"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              ></el-option>
            </el-select>
            <el-select
              v-model="form.area"
              placeholder="县"
              style="width: 120px"
            >
              <el-option
                v-for="list in arealist"
                :key="list.id"
                :label="list.title"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地址">
            <el-input
              v-model="form.address"
              style="width: 550px"
              placeholder="地址"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="来源">
            <el-select
              v-model="form.from"
              placeholder="来源"
              style="width: 200px"
            >
              <el-option
                v-for="list in from"
                :key="list.id"
                :label="list.name"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推荐人">
            <el-input
              v-model="form.parent_name"
              placeholder="推荐人"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="开通试用">
            <el-switch
              v-model="form.is_close"
              :active-value="0"
              :inactive-value="1"
              :width="60"
            ></el-switch>
          </el-form-item>
          <el-form-item label="归属">
            <el-select
              v-model="form.vest_in"
              placeholder="归属"
              style="width: 200px"
            >
              <el-option
                v-for="list in vestlist"
                :key="list.id"
                :label="list.truename"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="起止日期">
            <el-date-picker
              v-model="form.time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <br />
          <el-form-item label="管理员账号">
            <el-input
              v-model="form.login_user"
              placeholder="管理员账号"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input
              v-model="form.password"
              placeholder="密码"
              show-password
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <br />

          <el-form-item label="端口数上限">
            <!--试用端口-->
            <el-input
              v-show="form.is_close == 1"
              v-model="form.test_account_num"
              style="width: 100px"
              placeholder="端口数上限"
              type="Number"
            ></el-input>
            <!--正式端口-->
            <el-input
              v-show="form.is_close == 0"
              v-model="form.account_num"
              style="width: 100px"
              placeholder="端口数上限"
              type="Number"
            ></el-input>
          </el-form-item>
          <br />
          <el-form-item label="备注">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="请输入备注"
              style="width: 550px"
            >
              >
            </el-input>
          </el-form-item>
          <el-form-item label="">
            <div style="display: flex; margin-left: 120px">
              <div>
                <el-switch
                  v-model="form.ckzx_falg"
                  active-text="仓库直销可选业务员"
                  :inactive-value="0"
                  :active-value="1"
                ></el-switch>
              </div>
              <div>
                <el-switch
                  v-model="form.photo_flag"
                  active-text="单据拍照功能（访销、车销、调拨单）"
                  :inactive-value="0"
                  :active-value="1"
                ></el-switch>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="button == 3" type="warning" @click="handlerReview">
          审核
        </el-button>
        <el-button
          v-show="button == 2 || button == 3"
          type="primary"
          @click="handlersave"
        >
          保存
        </el-button>
        <el-button v-show="button == 1" type="primary" @click="handlercreate">
          创建
        </el-button>
        <el-button @click="showusers = !showusers">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import MD5 from 'md5'
  import { postAction } from '@/api/Employee'
  export default {
    components: {},
    data() {
      var prov = (rule, value, callback) => {
        if (
          this.form.province === '' ||
          this.form.city === '' ||
          this.form.area === ''
        ) {
          callback(new Error('请选择所在地'))
        } else {
          callback()
        }
      }
      var checkPhone = (rule, value, callback) => {
        if (this.form.mobile != '') {
          return callback()
        } else {
          const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/
          if (reg.test(this.form.mobile)) {
            callback()
          } else {
            return callback(new Error('请输入正确的手机号'))
          }
        }
      }
      return {
        button: 1,
        title: '添加注册用户',
        showusers: false,
        url: {
          industry: '/superAdmin/dealer/get-industry',
          area: '/baseAdmin/common/common-area',
          vest: 'superAdmin/user/index',
          ID: 'superAdmin/dealer/get-no',
          create: '/superAdmin/dealer/create',
          info: '/superAdmin/dealer/detail',
          edit: '/superAdmin/dealer/update',
          Review: '/superAdmin/dealer/check',
        },
        ID: '',
        form: {
          dealer_name: '', //企业名称
          boss: '', //联系人
          mobile: '', //手机
          phone: '', //电话
          guimo: '', //规模  1(20人)2(20-50人)3(50-100人)4(100人以上)
          leixing: '', //类型 1生产企业、2集团经销商、3地区经销商
          hangye: [], //行业  逗号隔开如：1,2,3 取值从行业列表
          province: '', //省份ID
          city: '', //城市ID
          area: '', //地区ID
          address: '', //地址
          from: '', //来源 1APP注册2PC注册3工作站4转介绍
          parent_name: '', //推荐人姓名
          is_close: 0, //开通使用 0开1关
          vest_in: '', //归属ID
          start_time: '', //开始时间 如2025-10-20
          end_time: '', //结束时间 如2025-10-20
          time: [],
          login_user: '', //管理员账号
          password: '', //密码 md5加密后32位
          test_account_num: '', //试用账号数
          account_num: '', //正式账号数
          remark: '', //备注
        },
        rules: {
          dealer_name: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
          boss: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
          mobile: [
            {
              required: true,
              validator: checkPhone,
              trigger: 'blur',
            },
          ],
          province: [
            {
              required: true,
              validator: prov,
              trigger: 'change',
            },
          ],
        },
        guimolist: [
          {
            id: '1', //1(20人)2(20-50人)3(50-100人)4(100人以上)
            name: '20人',
          },
          {
            id: '2', //1(20人)2(20-50人)3(50-100人)4(100人以上)
            name: '20-50人',
          },
          {
            id: '3', //1(20人)2(20-50人)3(50-100人)4(100人以上)
            name: '50-100人',
          },
          {
            id: '4', //1(20人)2(20-50人)3(50-100人)4(100人以上)
            name: '100人以上',
          },
        ],
        leixinglist: [
          {
            id: '1', //1生产企业、2集团经销商、3地区经销商
            name: '生产企业',
          },
          {
            id: '2', //1生产企业、2集团经销商、3地区经销商
            name: '集团经销商',
          },
          {
            id: '3', //1生产企业、2集团经销商、3地区经销商
            name: '地区经销商',
          },
        ],
        from: [
          {
            id: '1',
            name: 'APP注册',
          },
          {
            id: '2',
            name: 'PC注册',
          },
          {
            id: '3',
            name: '工作站',
          },
          {
            id: '4',
            name: '转介绍',
          },
        ],
        hangyelist: [],
        provincelist: [],
        citylist: [],
        arealist: [],
        vestlist: [],
      }
    },

    computed: {},
    watch: {
      'form.time'(val) {
        console.log(val, '')
        this.form.start_time = val[0]
        this.form.end_time = val[1]
      },
      showusers(val) {
        if (!val) {
          this.resetForm()
          this.button = 1
          this.title = '添加注册用户'
          this.form = {
            dealer_name: '', //企业名称
            boss: '', //联系人
            mobile: '', //手机
            phone: '', //电话
            guimo: '', //规模  1(20人)2(20-50人)3(50-100人)4(100人以上)
            leixing: '', //类型 1生产企业、2集团经销商、3地区经销商
            hangye: [], //行业  逗号隔开如：1,2,3 取值从行业列表
            province: '', //省份ID
            city: '', //城市ID
            area: '', //地区ID
            address: '', //地址
            from: '', //来源 1APP注册2PC注册3工作站4转介绍
            parent_name: '', //推荐人姓名
            is_close: 0, //开通使用 0开1关
            vest_in: '', //归属ID
            start_time: '', //开始时间 如2025-10-20
            end_time: '', //结束时间 如2025-10-20
            time: [],
            login_user: '', //管理员账号
            password: '', //密码 md5加密后32位
            test_account_num: '', //试用账号数
            account_num: '', //正式账号数
            remark: '', //备注
          }
        }
      },
    },
    created() {},
    mounted() {
      this.handlerindustry()
      this.handlerSheng()
      this.handlervest()
      this.handlerID()
    },
    methods: {
      //用户保存
      handlersave() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var data = JSON.parse(JSON.stringify(this.form))
            delete data.time
            delete data.order
            // delete data.heji
            // delete data.mall_falg
            // delete data.mall_limit
            if (data.password) {
              data.password = MD5(data.password.trim())
              console.log(data.password, '')
            }
            if (data.hangye) {
              data.hangye = data.hangye.join(',')
            }
            postAction(this.url.edit, data)
              .then((res) => {
                console.log(res, '')
                this.showusers = false
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err, '')
              })
          } else {
            this.$message({
              message: '请完成必填项',
              type: 'warning',
            })
            return false
          }
        })
      },
      //用户审核
      handlerReview() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var data = JSON.parse(JSON.stringify(this.form))
            delete data.time
            delete data.order
            delete data.heji
            delete data.mall_falg
            delete data.mall_limit
            console.log(data.password, '密码未加密前！')
            if (data.password) {
              data.password = MD5(data.password.trim())
              console.log(data.password, '密码！')
            }
            if (data.hangye) {
              data.hangye = data.hangye.join(',')
            }
            console.log(data, '')
            postAction(this.url.Review, data)
              .then((res) => {
                console.log(res, '')
                this.showusers = false
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err, '')
              })
          } else {
            this.$message({
              message: '请完成必填项',
              type: 'warning',
            })
            return false
          }
        })
      },
      //获取用户详情
      handlerinfo(row) {
        console.log(row, '')

        postAction(this.url.info, { id: row.id, dealer_id: row.dealer_id })
          .then((res) => {
            console.log(res.data, '用户详情')
            this.form = res.data
            this.form.dealer_id = row.dealer_id
            this.form.id = row.id
            this.form.is_close = Number(this.form.is_close)
            this.$set(this.form, 'password', '')
            this.form.hangye =
              this.form.hangye == '' ? '' : this.form.hangye.split(',')
            this.$set(
              this.form,
              'time',
              Array.of(String(res.data.start_time), String(res.data.end_time))
            )
            console.log(this.form, this.form.hangye)
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //获取编号
      handlerID() {
        postAction(this.url.ID, {})
          .then((res) => {
            console.log(res.data, '')
            this.ID = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //获取省
      handlerSheng() {
        this.handlerssq('').then((res) => {
          console.log(res, '省')
          this.provincelist = res
        })
      },
      //获取市
      handlerprovince(e) {
        console.log(e, '市')
        this.form.city = ''
        this.form.area = ''
        this.handlerssq(e).then((res) => {
          console.log(res, '市')
          this.citylist = res
        })
      },
      //获取区
      handlercity(e) {
        console.log(e, '区')
        this.form.area = ''
        this.handlerssq(e).then((res) => {
          console.log(res, '区')
          this.arealist = res
        })
      },
      handlercreate() {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var data = JSON.parse(JSON.stringify(this.form))
            delete data.time
            if (data.password != '' || data.password != undefined) {
              data.password = MD5(data.password.trim())
              console.log(data.password, '')
            }
            if (data.hangye) {
              data.hangye = data.hangye.join(',')
            }
            postAction(this.url.create, data)
              .then((res) => {
                console.log(res, '')
                this.showusers = false
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err, '')
              })
          } else {
            this.$message({
              message: '请完成必填项',
              type: 'warning',
            })
            return false
          }
        })
      },
      resetForm() {
        this.$refs['form'].resetFields()
      },
      //归属
      handlervest() {
        postAction(this.url.vest, {})
          .then((res) => {
            console.log(res, '归属')
            this.vestlist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //省市区
      handlerssq(ID) {
        let promise = new Promise((Result, reject) => {
          postAction(this.url.area, { parent_id: ID })
            .then((res) => {
              Result(res.data)
            })
            .catch((err) => {
              console.log(err, '')
              reject(err)
            })
        })
        return promise
      },
      //行业列表
      handlerindustry() {
        postAction(this.url.industry, {})
          .then((res) => {
            console.log(res, '行业')
            this.hangyelist = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
