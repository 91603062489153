<!--
 * @Author: 候怀烨
 * @Date: 2021-04-28 15:57:43
 * @LastEditTime: 2021-05-12 11:30:26
 * @LastEditors: Please set LastEditors
 * @Description: 注册用户
 * @FilePath: \sd-vue-admin\src\views\project\admin\userManagement\registeredUser\index.vue
-->
<template>
  <!-- NAME[epic=admin] 注册用户 -->
  <div class="orderTest-container">
    <el-row>
      <el-col>
        <el-form ref="form" :model="form" inline>
          <el-form-item prop="keyword">
            <el-input
              v-model="form.keyword"
              placeholder="企业ID或名称"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="联系姓名或手机号"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="area_id">
            <el-cascader
              v-model="form.area_id"
              filterable
              placeholder="地区"
              :options="area"
              :props="{
                checkStrictly: true,
                emitPath: false,
                value: 'id',
                label: 'title',
              }"
              clearable
            ></el-cascader>
          </el-form-item>
          <el-form-item prop="vest_in">
            <el-select
              v-model="form.vest_in"
              style="width: 150px"
              placeholder="归属"
            >
              <el-option
                v-for="list in vest"
                :key="list.id"
                :value="list.id"
                :label="list.truename"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="parent_name">
            <el-input
              v-model="form.parent_name"
              placeholder="推荐人姓名"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="from">
            <el-select
              v-model="form.from"
              style="width: 150px"
              placeholder="来源"
            >
              <el-option
                v-for="list in from"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="status">
            <el-select
              v-model="form.status"
              style="width: 150px"
              placeholder="状态"
            >
              <el-option
                v-for="list in status"
                :key="list.id"
                :value="list.id"
                :label="list.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerchognzhi">重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="createusers">创建</el-button>
          </el-form-item>

          <el-form-item>
            <el-button @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-table stripe :data="tableData">
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          >
            <template v-if="list.label == '状态'" #default="{ row }">
              <span v-show="row.is_close == 0">试用</span>
              <span v-show="row.is_close == 1">封存</span>
              <span v-show="row.is_close == 2">待审核</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="250">
            <template #default="{ row }">
              <!-- is_close 2 待审核 0试用中 1封存  -->
              <!-- 待审核           审核   编辑   查看   删除
                  试用             客情   登陆   编辑   账密   员工   签约   封存   查看
                  封存             查看   启动
 -->
              <div v-if="row.is_close == 0">
                <el-button type="text" @click="handleCustDetail(row)">
                  客情
                </el-button>
                <el-button type="text" @click="handlerlogin($index, row)">
                  登陆
                </el-button>
                <el-button type="text" @click="handleredit(row)">
                  编辑
                </el-button>
                <el-button type="text" @click="handleAccount(row)">
                  账密
                </el-button>
                <el-button type="text" @click="handlerstaff(row)">
                  员工
                </el-button>
                <el-button type="text" @click="handleSigning(row)">
                  签约
                </el-button>
                <el-button type="text" @click="handleStaffStop(row)">
                  封存
                </el-button>
                <el-button type="text" @click="handleCheck(row)">
                  查看
                </el-button>
              </div>
              <div v-else-if="row.is_close == 1">
                <el-button type="text" @click="handleCheck(row)">
                  查看
                </el-button>
                <el-button type="text">启动</el-button>
              </div>
              <div v-else-if="row.is_close == 2">
                <el-button type="text" @click="handlershenhe(row)">
                  审核
                </el-button>
                <el-button type="text" @click="handleredit(row)">
                  编辑
                </el-button>
                <el-button type="text" @click="handleCheck(row)">
                  查看
                </el-button>
                <el-button type="text">删除</el-button>
              </div>
              <span v-else></span>

              <!-- <el-button type="text" @click="handlershenhe(row)">
                审核
              </el-button>
              <el-button type="text" @click="handleCustDetail(row)">
                客情
              </el-button>
              <el-button type="text">登陆</el-button>
              <el-button type="text" @click="handleredit(row)">编辑</el-button>
              <el-button type="text" @click="handleAccount(row)">
                账密
              </el-button>
              <el-button type="text" @click="handlerstaff(row)">员工</el-button>
              <el-button type="text" @click="handleSigning(row)">
                签约
              </el-button>
              <el-button type="text" @click="handleStaffStop(row)">
                封存
              </el-button>
              <el-button type="text" @click="handleCheck(row)">查看</el-button>
              <el-button type="text">启动</el-button>
              <el-button type="text">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-users ref="users" @getlist="getlist"></add-users>
    <staff-user ref="staff"></staff-user>
    <cust-detail ref="custDetail"></cust-detail>
    <account ref="account" @refresh="handlerlist"></account>
    <signing ref="signing" @refresh="handlerlist"></signing>
    <check ref="check"></check>
  </div>
</template>
<script>
  import StaffUser from './components/StaffUser.vue'
  import { downloadFile, postAction } from '@/api/Employee'
  import {
    guishuSelectList,
    registerStaffStop,
  } from '@/api/admin/userManagement'
  import AddUsers from './components/AddUsers.vue'
  import CustDetail from './components/custDetail.vue'
  import Account from './components/account'
  import Signing from './components/signing'
  import Check from './components/detail'
  import store from '@/store'
  export default {
    name: 'RegisteredUser',
    components: { AddUsers, StaffUser, CustDetail, Account, Signing, Check },
    data() {
      return {
        form: {
          pageSize: 10,
          pageNo: 1,
          area_id: '', //地区ID
          status: '', //状态 2待审核 0试用中 1已封存
          from: '', //来源 1APP2注册3工作站4转介绍
          vest_in: '', //归属ID
          keyword: '', //企业ID或名称
          name: '', //负责人姓名或手机号
          parent_name: '', //推荐人姓名
        },
        status: [
          { name: '待审核', id: 2 },
          { name: '试用中', id: 0 },
          { name: '已封存', id: 1 },
        ], //0试用中 1已封存 ,1
        from: [
          { name: 'APP2注册', id: 1 },
          { name: '工作站', id: 3 },
          { name: '转介绍', id: 4 },
        ],
        vest: [],
        area: [],
        tableData: [],
        total: 0,
        colemd: [
          {
            label: '客户ID',
            align: 'center',
            prop: 'dealer_id',
            width: '',
          },
          {
            label: '企业名称',
            align: 'center',
            prop: 'comp',
            width: '',
          },
          {
            label: '联系人',
            align: 'center',
            prop: 'boss',
            width: '',
          },
          {
            label: '手机',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'is_close',
            width: '',
          },
          {
            label: '试用到期',
            align: 'center',
            prop: 'end_time',
            width: '',
          },
          {
            label: '注册时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
          {
            label: '归属',
            align: 'center',
            prop: 'vest_name',
            width: '',
          },
          {
            label: '地区',
            align: 'center',
            prop: 'sheng',
            width: '',
          },
          {
            label: '地址',
            align: 'center',
            prop: 'address',
            width: '',
          },
        ],
        url: {
          list: '/superAdmin/dealer/index',
          area: '/baseAdmin/common/province',
          vest: '/superAdmin/user/index',
        },
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlerarea()
      this.handlervest()
      this.handlerInquire()
    },
    methods: {
      //登录
      async handlerlogin(idx, row) {
        let hasToken = store.getters['user/accessToken']
        console.log(hasToken, 'hasToken1')
        let a = hasToken.split('*')
        console.log(a, 'hasToken2', row.user_token)
        let t = ''
        if (a.length == 1) {
          t = row.user_token + '*' + hasToken
        } else {
          t = row.user_token + '*' + a[1]
        }
        console.log(t)
        // this.setAccessToken(t)
        // console.log(this.$store)

        // store.getters['user/setAccessToken']
        store.commit('user/setAccessToken', t)
        // console.log(store.getters['user/accessToken'])
        this.$router.push('/')
        location.reload()
      },
      //员工
      handlerstaff(row) {
        this.$refs.staff.showstaff = true
        this.$refs.staff.form.dealer_id = row.dealer_id
        this.$refs.staff.form.id = row.id
        this.$refs.staff.handlerrole(row.dealer_id)
        this.$refs.staff.handlerdepart(row.dealer_id)
        console.log(row, '')
      },
      getlist() {
        this.handlerInquire()
      },
      //创建
      createusers() {
        this.$refs.users.showusers = true
      },
      //编辑
      async handleredit(row) {
        this.$refs.users.showusers = true
        this.$refs.users.button = 2
        this.$refs.users.title = '编辑注册用户'
        await this.$refs.users.handlerprovince(row.province)
        await this.$refs.users.handlercity(row.city)
        await this.$refs.users.handlerinfo(row)
      },
      async handlershenhe(row) {
        this.$refs.users.title = '审核注册用户'
        this.$refs.users.showusers = true
        this.$refs.users.button = 3
        await this.$refs.users.handlerprovince(row.province)
        await this.$refs.users.handlercity(row.city)
        await this.$refs.users.handlerinfo(row)
      },
      handlerchognzhi() {
        this.$refs['form'].resetFields()
      },
      async handlervest() {
        // postAction(this.url.vest, {})
        //   .then((res) => {
        //     console.log(res, '')
        //     this.vest = res.data
        //   })
        //   .catch((err) => {
        //     console.log(err, '')
        //   })
        let { data } = await guishuSelectList({ pageSize: -1 })
        this.vest = data
      },
      handlerarea() {
        postAction(this.url.area, {})
          .then((res) => {
            console.log(res, '')
            this.area = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
      // 客情
      handleCustDetail(row) {
        this.$refs.custDetail.id = row.id
        this.$refs.custDetail.row = row
        this.$refs.custDetail.dealer_id = row.dealer_id
        this.$refs.custDetail.showDialog = true
      },
      handleAccount(row) {
        this.$refs.account.id = row.id
        this.$refs.account.title = row.comp
        this.$refs.account.dealer_id = row.dealer_id
        this.$refs.account.showDialog = true
      },
      handleSigning(row) {
        this.$refs.signing.id = row.id
        this.$refs.signing.dealer_id = row.dealer_id
        this.$refs.signing.showDialog = true
      },
      handleCheck(row) {
        this.$refs.check.id = row.id
        this.$refs.check.dealer_id = row.dealer_id
        this.$refs.check.showDialog = true
      },
      // 封存
      handleStaffStop(row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            registerStaffStop({
              id: row.dealer_id,
            }).then((res) => {
              console.log(res)
              if (res.code == 200) {
                this.$message.success('封存成功')
                this.handlerlist()
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleExport() {
        downloadFile(
          '/superAdmin/dealer/index-export',
          '注册用户.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
