<template>
  <el-dialog
    :modal="false"
    title="签约正式用户"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="企业名称:" prop="dealer_name">
          <el-input v-model="topForm.dealer_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="ID:" prop="dealer_id">
          <el-input v-model="topForm.dealer_id" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="开始日期:" prop="start_time">
          <el-date-picker
            v-model="topForm.start_time"
            type="date"
            placeholder="开通日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            disabled
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="到期日期:" prop="end_time">
          <el-date-picker
            v-model="topForm.end_time"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            disabled
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型1</el-col>
          <el-col :span="6">开通数量</el-col>
          <el-col :span="6">赠送数量</el-col>
          <el-col :span="6">试用上限</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">开通端口</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.port_add"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.port_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.port_test"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型2</el-col>
          <el-col :span="6">时长(月)</el-col>
          <el-col :span="6">赠送(月)</el-col>
          <el-col :span="6">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">签约时长</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.time_add"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.time_gift"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.time_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="6">类型3</el-col>
          <el-col :span="6">网销开关</el-col>
          <el-col :span="6">门店上限</el-col>
          <el-col :span="6">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="6">
            <div class="grid-content" style="line-height: 35px">网销</div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <!-- <el-input v-model="form.mall_falg"></el-input> -->
              <el-switch
                v-model="form.mall_falg"
                :active-value="1"
                :inactive-value="2"
                active-color="#13ce66"
                inactive-color="#ff4949"
              ></el-switch>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.mall_limit"></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <el-input v-model="form.mall_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="table" style="text-align: center">
        <el-row
          :gutter="20"
          style="line-height: 35px; color: '#606266'; background-color: #f5f7fa"
        >
          <el-col :span="4">类型4</el-col>
          <el-col :span="5">培训日期</el-col>
          <el-col :span="5">培训人</el-col>
          <el-col :span="5">天数</el-col>
          <el-col :span="5">金额</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content" style="line-height: 35px">培训</div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content" style="line-height: 35px">
              <!-- teach_date -->
              <el-date-picker
                v-model="form.teach_date"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <!-- <el-input v-model="form.teach_user"></el-input> -->
              <el-select
                v-model="form.teach_user"
                clearable
                style="width: 100%"
                placeholder="培训人"
              >
                <el-option
                  v-for="(i, idx) in peixunrenList"
                  :key="idx"
                  :value="i.id"
                  :label="i.username"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.teach_day"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content">
              <el-input v-model="form.teach_money"></el-input>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div style="margin-top: 10px; text-align: right">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    guishuSelectList,
    registerDetail,
    registerSigning,
  } from '@/api/admin/userManagement'
  export default {
    name: 'Signing',
    components: {},
    data() {
      return {
        showDialog: false,
        dealer_id: null,
        id: null,
        peixunrenList: [],
        topForm: {
          dealer_name: '',
          dealer_id: '',
          start_time: '',
          end_time: '',
        },
        form: {
          // table1
          port_add: '',
          port_gift: '',
          port_test: '',
          // table2
          time_add: '',
          time_gift: '',
          time_money: '',
          // table3
          mall_falg: 1,
          mall_limit: '',
          mall_money: '',
          // table4
          teach_date: '',
          teach_user: '',
          teach_day: '',
          teach_money: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.topForm = {
            dealer_name: '',
            dealer_id: '',
            start_time: '',
            end_time: '',
          }
          this.form = {
            // table1
            port_add: '',
            port_gift: '',
            port_test: '',
            // table2
            time_add: '',
            time_gift: '',
            time_money: '',
            // table3
            mall_falg: 1,
            mall_limit: '',
            mall_money: '',
            // table4
            teach_date: '',
            teach_user: '',
            teach_day: '',
            teach_money: '',
          }
        }
      },
    },
    created() {},
    mounted() {
      // 培训人下拉
      guishuSelectList({ pageSize: -1 }).then((res) => {
        this.peixunrenList = res.data
      })
    },
    methods: {
      async fetchData() {
        let { data } = await registerDetail({
          id: this.id,
          dealer_id: this.dealer_id,
        })
        this.topForm = data
      },
      async handleSubmit() {
        let form = Object.assign(this.form, { dealer_id: this.dealer_id })
        let { data, msg } = await registerSigning(form)
        this.$emit('refresh')
        console.log('data', data)
        this.$message.success(msg)
        this.close()
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
