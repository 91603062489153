<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      :title="title"
      :visible.sync="showDialog"
      width="400px"
      top="18vh"
      :close-on-click-modal="false"
      destroy-on-close
      center
    >
      <div class="orderTest-container">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item label="管理员账号" prop="account">
            <el-input v-model="form.account"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="form.password" type="password"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 15px; text-align: right">
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="close">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { registerUserAccount } from '@/api/admin/userManagement'
  import md5 from 'md5'
  export default {
    name: 'Account',
    components: {},
    data() {
      return {
        showDialog: false,
        title: 'title',
        id: 0,
        dealer_id: '',
        rules: {
          account: [
            {
              required: true,
              message: '请输入管理员账号',
              tigger: 'blur',
            },
          ],
          password: [
            {
              required: true,
              message: '请输入密码',
              tigger: 'blur',
            },
          ],
        },
        form: {
          account: '',
          password: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.form = {
            account: '',
            password: '',
          }
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      handleSave() {
        this.$refs.form.validate((val) => {
          console.log(val)
          if (val) {
            registerUserAccount({
              id: this.id,
              dealer_id: this.dealer_id,
              login_user: this.form.account,
              password: md5(this.form.password),
            }).then((res) => {
              this.$emit('refresh')
              this.$message.success(res.msg)
              this.close()
            })
          }
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
