var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title,
            visible: _vm.showusers,
            "append-to-body": "",
            "close-on-click-modal": false,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showusers = $event
            },
          },
        },
        [
          _c("el-divider"),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    rules: _vm.rules,
                    inline: "",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称", prop: "dealer_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "企业名称" },
                        model: {
                          value: _vm.form.dealer_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dealer_name", $$v)
                          },
                          expression: "form.dealer_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { disabled: "", placeholder: "ID" },
                        model: {
                          value: _vm.ID,
                          callback: function ($$v) {
                            _vm.ID = $$v
                          },
                          expression: "ID",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "boss" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "联系人" },
                        model: {
                          value: _vm.form.boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "boss", $$v)
                          },
                          expression: "form.boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机", prop: "mobile" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "手机" },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "电话" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规模" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "规模" },
                          model: {
                            value: _vm.form.guimo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "guimo", $$v)
                            },
                            expression: "form.guimo",
                          },
                        },
                        _vm._l(_vm.guimolist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "机构类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "机构类型" },
                          model: {
                            value: _vm.form.leixing,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "leixing", $$v)
                            },
                            expression: "form.leixing",
                          },
                        },
                        _vm._l(_vm.leixinglist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "行业" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "行业", multiple: "" },
                          model: {
                            value: _vm.form.hangye,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "hangye", $$v)
                            },
                            expression: "form.hangye",
                          },
                        },
                        _vm._l(_vm.hangyelist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地", prop: "province" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "省" },
                          on: { change: _vm.handlerprovince },
                          model: {
                            value: _vm.form.province,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "province", $$v)
                            },
                            expression: "form.province",
                          },
                        },
                        _vm._l(_vm.provincelist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.title, value: list.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px", margin: "0px 20px" },
                          attrs: { placeholder: "市" },
                          on: { change: _vm.handlercity },
                          model: {
                            value: _vm.form.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "city", $$v)
                            },
                            expression: "form.city",
                          },
                        },
                        _vm._l(_vm.citylist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.title, value: list.id },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "县" },
                          model: {
                            value: _vm.form.area,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "area", $$v)
                            },
                            expression: "form.area",
                          },
                        },
                        _vm._l(_vm.arealist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.title, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "550px" },
                        attrs: { placeholder: "地址" },
                        model: {
                          value: _vm.form.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "来源" },
                          model: {
                            value: _vm.form.from,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "from", $$v)
                            },
                            expression: "form.from",
                          },
                        },
                        _vm._l(_vm.from, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.name, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "推荐人" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "推荐人" },
                        model: {
                          value: _vm.form.parent_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parent_name", $$v)
                          },
                          expression: "form.parent_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "开通试用" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-value": 0,
                          "inactive-value": 1,
                          width: 60,
                        },
                        model: {
                          value: _vm.form.is_close,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "is_close", $$v)
                          },
                          expression: "form.is_close",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "归属" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "200px" },
                          attrs: { placeholder: "归属" },
                          model: {
                            value: _vm.form.vest_in,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vest_in", $$v)
                            },
                            expression: "form.vest_in",
                          },
                        },
                        _vm._l(_vm.vestlist, function (list) {
                          return _c("el-option", {
                            key: list.id,
                            attrs: { label: list.truename, value: list.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "起止日期" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.form.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "time", $$v)
                          },
                          expression: "form.time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "管理员账号" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "管理员账号" },
                        model: {
                          value: _vm.form.login_user,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "login_user", $$v)
                          },
                          expression: "form.login_user",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "密码" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "密码", "show-password": "" },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "端口数上限" } },
                    [
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.is_close == 1,
                            expression: "form.is_close == 1",
                          },
                        ],
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "端口数上限", type: "Number" },
                        model: {
                          value: _vm.form.test_account_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "test_account_num", $$v)
                          },
                          expression: "form.test_account_num",
                        },
                      }),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.form.is_close == 0,
                            expression: "form.is_close == 0",
                          },
                        ],
                        staticStyle: { width: "100px" },
                        attrs: { placeholder: "端口数上限", type: "Number" },
                        model: {
                          value: _vm.form.account_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "account_num", $$v)
                          },
                          expression: "form.account_num",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "550px" },
                          attrs: {
                            type: "textarea",
                            placeholder: "请输入备注",
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        },
                        [_vm._v(" > ")]
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-left": "120px",
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-text": "仓库直销可选业务员",
                                "inactive-value": 0,
                                "active-value": 1,
                              },
                              model: {
                                value: _vm.form.ckzx_falg,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "ckzx_falg", $$v)
                                },
                                expression: "form.ckzx_falg",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-text":
                                  "单据拍照功能（访销、车销、调拨单）",
                                "inactive-value": 0,
                                "active-value": 1,
                              },
                              model: {
                                value: _vm.form.photo_flag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "photo_flag", $$v)
                                },
                                expression: "form.photo_flag",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.button == 3,
                      expression: "button == 3",
                    },
                  ],
                  attrs: { type: "warning" },
                  on: { click: _vm.handlerReview },
                },
                [_vm._v(" 审核 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.button == 2 || _vm.button == 3,
                      expression: "button == 2 || button == 3",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlersave },
                },
                [_vm._v(" 保存 ")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.button == 1,
                      expression: "button == 1",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.handlercreate },
                },
                [_vm._v(" 创建 ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showusers = !_vm.showusers
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }