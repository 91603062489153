<!--
 * @Author: your name
 * @Date: 2021-05-11 14:34:03
 * @LastEditTime: 2021-05-12 10:08:22
 * @LastEditors: Please set LastEditors
 * @Description: 员工
 * @FilePath: \sd-vue-admin\src\views\project\admin\userManagement\registeredUser\components\StaffUser.vue
-->
<template>
  <div class="orderTest-container">
    <el-dialog :modal="false" :title="title" center :visible.sync="showstaff">
      <div>
        <el-form :model="form" inline>
          <el-form-item>
            <el-select
              v-model="form.depart_id"
              placeholder="部门"
              style="width: 200px"
            >
              <el-option
                v-for="list in departlist"
                :key="list.id"
                :label="list.text"
                :value="list.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.role"
              placeholder="角色"
              style="width: 200px"
            >
              <el-option
                v-for="list in rolelist"
                :key="list.role_id"
                :label="list.role_name"
                :value="list.role_id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.keyword"
              placeholder="姓名、手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerfind">查 询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="table"
          stripe
          :data="tableData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="40"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(list, index) in colemd"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          ></el-table-column>
          <el-table-column>
            <template #default="{ row }">
              <el-button type="text" @click="handlerxu(row.user_id)">
                续期
              </el-button>
              <!-- <el-button type="text" @click="handlerstop(row.user_id)">
                封存
              </el-button> -->
              <el-popconfirm
                v-show="row.bill_status == 1"
                title="您真的要封存这个吗？"
                @confirm="handlerstop(row.user_id)"
              >
                <el-button
                  slot="reference"
                  style="margin-left: 10px"
                  type="text"
                >
                  封存
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-row type="flex" class="row-bg" :gutter="2">
          <el-col :span="2">
            <el-button type="primary" @click="handlershowR">续期</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" @click="handlerselectf">封存</el-button>
          </el-col>
        </el-row>
      </div>
      <el-dialog
        :modal="false"
        width="30%"
        title="续期"
        :visible.sync="showRenew"
        append-to-body
        center
      >
        <div>
          <el-form :model="Rform" inline label-width="150px">
            <el-form-item label="续期：">
              <el-input
                v-model="Rform.day_num"
                placeholder="续期天数"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="Renewaloperation">确 认</el-button>
          <el-button @click="showRenew = !showRenew">关 闭</el-button>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showstaff = !showstaff">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    components: {},

    data() {
      return {
        title: '企业',
        url: {
          role: '/baseAdmin/common/role-list',
          depart: '/baseAdmin/common/depart-list',
          staff: '/superAdmin/dealer/staff',
          stop: '/superAdmin/dealer/staff-status',
          Renew: '/superAdmin/dealer/staff-renewal',
        },
        rolelist: [],
        departlist: [],
        showstaff: false,
        showRenew: false,
        Rform: {
          id: '', //员工ID 如1,2,3
          day_num: '', //续期天数
        },
        form: {
          depart_id: '', //部门ID
          role: '', //角色ID
          keyword: '', //姓名、手机号
          id: '', //列表中返回的数据ID
          dealer_id: '', //列表中返回的dealer_ID
        },
        tableData: [],
        colemd: [
          {
            label: '姓名',
            align: 'center',
            prop: 'dealer_id',
            width: '',
          },
          {
            label: '登录手机',
            align: 'center',
            prop: 'comp',
            width: '',
          },
          {
            label: '角色',
            align: 'center',
            prop: 'boss',
            width: '',
          },
          {
            label: '部门',
            align: 'center',
            prop: 'mobile',
            width: '',
          },
          {
            label: '创建日期',
            align: 'center',
            prop: 'is_close',
            width: '',
          },
          {
            label: '试用到期',
            align: 'center',
            prop: 'end_time',
            width: '',
          },
        ],
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handlershowR() {
        this.showRenew = true
      },
      handlerxu(id) {
        this.Rform.id = id
      },
      handleSelectionChange(e) {
        console.log(e, '选中的')
        this.Rform.id = e
          .map((list) => {
            return list.user_id
          })
          .toString()
      },
      handlerselectf() {
        this.handlerstop(this.Rform.id)
      },
      Renewaloperation() {
        postAction(this.url.Renew, this.Rform)
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.showRenew = false
            this.handlerfind()
          })
          .catch((err) => {
            console.log(err)
            this.$message({
              message: res.msg,
              type: 'error',
            })
          })
      },
      handlerstop(id) {
        postAction(this.url.stop, { id: id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerfind()
          })
          .catch((err) => {
            console.log(err)
            this.$message({
              message: res.msg,
              type: 'error',
            })
          })
      },
      handlerfind() {
        postAction(this.url.staff, this.form)
          .then((res) => {
            console.log(res, '')
            this.tableData = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //角色
      handlerrole(id) {
        postAction(this.url.role, { dealer_id: id })
          .then((res) => {
            console.log(res, '角色')
            this.rolelist = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //部门
      handlerdepart(id) {
        postAction(this.url.depart, { dealer_id: id })
          .then((res) => {
            console.log(res, '部门')
            this.departlist = res.data.list
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .row-bg {
    margin-top: 20px;
  }
</style>
