<template>
  <el-dialog
    :modal="false"
    title="注册用户"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        disabled
        :model="form"
        inline
        label-position="right"
        label-width="95px"
      >
        <el-form-item label="企业名称:" prop="dealer_name">
          <el-input v-model="form.dealer_name"></el-input>
        </el-form-item>
        <el-form-item label="ID:" prop="dealer_id">
          <el-input v-model="form.dealer_id" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="boss">
          <el-input v-model="form.boss"></el-input>
        </el-form-item>
        <el-form-item label="手机:" prop="mobile">
          <el-input v-model="form.mobile"></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="规模:" prop="guimo">
          <!-- <el-input v-model="form.guimo"></el-input> -->
          <el-select
            v-model="form.guimo"
            clearable
            placeholder="规模"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in guimoList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构类型:" prop="leixing">
          <el-select
            v-model="form.leixing"
            clearable
            placeholder="机构类型"
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in leixingList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业:" prop="hangye">
          <!-- <el-input v-model="form.hangye"></el-input> -->
          <el-select
            v-model="form.hangye"
            clearable
            placeholder="行业"
            multiple
            style="width: 200px"
          >
            <el-option
              v-for="(i, idx) in hangyeList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在地:" prop="local" style="width: 100%">
          <el-select
            v-model="form.province"
            clearable
            style="width: 100px; margin-right: 15px"
            placeholder="省"
          >
            <el-option
              v-for="(i, idx) in shengList"
              :key="idx"
              :value="i.id"
              :label="i.title"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.city"
            clearable
            style="width: 100px; margin-right: 15px"
            placeholder="市"
          >
            <el-option
              v-for="(i, idx) in shiList"
              :key="idx"
              :value="i.id"
              :label="i.title"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.area"
            clearable
            style="width: 100px; margin-right: 15px"
            placeholder="区"
          >
            <el-option
              v-for="(i, idx) in quList"
              :key="idx"
              :value="i.id"
              :label="i.title"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="地址:" prop="address">
          <el-input v-model="form.address" style="width: 504px"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="来源:" prop="from">
          <el-select
            v-model="form.from"
            clearable
            style="width: 200px"
            placeholder="来源"
          >
            <el-option
              v-for="(i, idx) in fromList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推荐人:" prop="parent_name">
          <el-input v-model="form.parent_name"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="开通使用:" prop="is_close">
          <!-- 开通使用 0开通1未开通 -->
          <el-switch
            v-model="form.is_close"
            :active-value="'0'"
            :inactive-value="'1'"
            active-color="#13ce66"
            inactive-color="#ff4949"
          ></el-switch>
        </el-form-item>
        <el-form-item label="归属:" prop="vest_in">
          <!-- <el-input v-model="form.vest_in"></el-input> -->
          <el-select
            v-model="form.vest_in"
            clearable
            style="width: 140px"
            placeholder="归属"
          >
            <el-option
              v-for="(i, idx) in vestList"
              :key="idx"
              :value="i.id"
              :label="i.username"
            ></el-option>
          </el-select>
        </el-form-item>
        <div></div>
        <el-form-item label="管理员账号:" prop="login_user">
          <el-input v-model="form.login_user"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="开始日期:" prop="start_time">
          <el-date-picker
            v-model="form.start_time"
            type="date"
            placeholder="开始日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            disabled
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期:" prop="end_time">
          <el-date-picker
            v-model="form.end_time"
            type="date"
            placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 200px"
            disabled
          ></el-date-picker>
        </el-form-item>
        <div></div>
        <el-form-item label="端口上限数:" prop="account_num">
          <el-input v-model="form.account_num"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="form.remark"
            placeholder="备注"
            style="width: 504px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 10px; text-align: right">
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    registerDetail,
    hangyeSelectList,
    localSelectList,
    guishuSelectList,
  } from '@/api/admin/userManagement'
  export default {
    name: 'Detail',
    components: {},
    data() {
      return {
        id: null,
        dealer_id: null,
        showDialog: false,
        loading: false,
        // 1(20人)2(20-50人)3(50-100人)4(100人以上)
        guimoList: [
          {
            id: '1',
            name: '20人',
          },
          {
            id: '2',
            name: '20-50人',
          },
          {
            id: '3',
            name: '50-100人',
          },
          {
            id: '4',
            name: '100人以上',
          },
        ],
        // 类型 1生产企业、2集团经销商、3地区经销商
        leixingList: [
          {
            id: '1',
            name: '生产企业',
          },
          {
            id: '2',
            name: '集团经销商',
          },
          {
            id: '3',
            name: '地区经销商',
          },
        ],
        // 行业 逗号隔开如：1,2,3 取值从行业列表
        hangyeList: [],
        // 来源 1APP注册2PC注册3工作站4转介绍
        fromList: [
          {
            id: '1',
            name: 'APP注册',
          },
          {
            id: '2',
            name: 'PC注册',
          },
          {
            id: '3',
            name: '工作站',
          },
          {
            id: '4',
            name: '转介绍',
          },
        ],
        // 归属
        vestList: [],
        shengList: [],
        shiList: [],
        quList: [],
        local: 0,
        form: {
          dealer_id: '', // '经销商ID',
          dealer_name: '', // '公司名称',
          boss: '', // '老板',
          mobile: '', // '手机',
          phone: '', // '电话',
          guimo: '', // '规模 1 2 3 4',
          leixing: '', // '类型 123',
          hangye: '', // '行业 1,2,3',
          province: '', // '省份ID',
          city: '', // '城市ID',
          area: '', // '地区ID',
          address: '', // '地址',
          from: '', // '来源',
          parent_name: '', // '推荐人姓名',
          vest_in: '', // '归属人ID',
          is_close: '', // '开通使用 0开通1未开通',
          start_time: '', // '开始时间',
          end_time: '', // '结束时间',
          login_user: '', // '账号',
          test_account_num: '', // '试用账号数',
          account_num: '', // '正式账号数',
          remark: '', // '备注',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.clearForm()
        }
      },
    },
    created() {
      this.initSelect()
      this.initLocalSelect()
      this.guishuList()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await registerDetail({
          id: this.id,
          dealer_id: this.dealer_id,
        })
        this.form = data
        // 市区下拉
        let shi = await localSelectList({ parent_id: data.province })
        this.shiList = shi.data
        // 区下拉
        let qu = await localSelectList({ parent_id: data.city })
        this.quList = qu.data

        // 行业处理
        this.form.hangye = data.hangye.split(',')
        this.loading = false
      },
      async initSelect() {
        let { data } = await hangyeSelectList()
        this.hangyeList = data
      },
      async guishuList() {
        let { data } = await guishuSelectList({ pageSize: -1 })
        this.vestList = data
      },
      async initLocalSelect() {
        let { data } = await localSelectList({ parent_id: this.local })
        this.shengList = data
      },
      clearForm() {
        this.$refs.form.resetFields()
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
