var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "客情维护",
            visible: _vm.showDialog,
            width: "1000px",
            top: "5vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "orderTest-container",
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-position": "right",
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "企业名称:", prop: "" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-decoration": "underline",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleEditCust(_vm.form.dealer_id)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.form.dealer_name) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "ID:", prop: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.form.dealer_id) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "端口数量:", prop: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.form.account_num) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "联系人:", prop: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.form.boss) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "手机:", prop: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.form.mobile) + " ")]
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "到期时间:", prop: "" },
                    },
                    [_vm._v(" " + _vm._s(_vm.form.end_time) + " ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-right": "10px",
                    "margin-bottom": "10px",
                    "text-align": "right",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAddRow },
                    },
                    [_vm._v("添加")]
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.list, height: "480px" } },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_at",
                      label: "日期",
                      width: "220",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "remark",
                      label: "内容",
                      width: "620",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "user_name",
                      label: "操作人",
                      width: "110",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                modal: false,
                title: "添加",
                visible: _vm.showDialog2,
                width: "500px",
                top: "18vh",
                "close-on-click-modal": false,
                "destroy-on-close": "",
                "append-to-body": "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showDialog2 = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "orderTest-container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form2",
                      attrs: {
                        model: _vm.form2,
                        "label-position": "right",
                        "label-width": "60px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "内容:", prop: "" } },
                        [
                          _c("el-input", {
                            staticStyle: { height: "100px" },
                            attrs: { type: "textarea", rows: "5" },
                            model: {
                              value: _vm.form2.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.form2, "content", $$v)
                              },
                              expression: "form2.content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { "margin-top": "", "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleAddContent },
                    },
                    [_vm._v("确定")]
                  ),
                  _c("el-button", { on: { click: _vm.close2 } }, [
                    _vm._v("取消"),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c("edit", { ref: "users" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }