<template>
  <div class="orderTest-container">
    <el-dialog
      :modal="false"
      title="客情维护"
      :visible.sync="showDialog"
      width="1000px"
      top="5vh"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div v-loading="loading" class="orderTest-container">
        <el-form
          ref="form"
          :model="form"
          inline
          label-position="right"
          label-width="80px"
        >
          <el-form-item label="企业名称:" prop="" style="width: 300px">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="handleEditCust(form.dealer_id)"
            >
              {{ form.dealer_name }}
            </span>
          </el-form-item>
          <el-form-item label="ID:" prop="" style="width: 300px">
            {{ form.dealer_id }}
          </el-form-item>
          <el-form-item label="端口数量:" prop="" style="width: 300px">
            {{ form.account_num }}
          </el-form-item>
          <el-form-item label="联系人:" prop="" style="width: 300px">
            {{ form.boss }}
          </el-form-item>
          <el-form-item label="手机:" prop="" style="width: 300px">
            {{ form.mobile }}
          </el-form-item>
          <el-form-item label="到期时间:" prop="" style="width: 300px">
            {{ form.end_time }}
          </el-form-item>
        </el-form>
        <div
          style="padding-right: 10px; margin-bottom: 10px; text-align: right"
        >
          <el-button type="primary" @click="handleAddRow">添加</el-button>
        </div>
        <el-table stripe :data="list" height="480px">
          <el-table-column
            prop="create_at"
            label="日期"
            width="220"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="remark"
            label="内容"
            width="620"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            label="操作人"
            width="110"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <!-- <edit ref="edit" @refresh="fetchData"></edit> -->
      <el-dialog
        :modal="false"
        title="添加"
        :visible.sync="showDialog2"
        width="500px"
        top="18vh"
        :close-on-click-modal="false"
        destroy-on-close
        append-to-body
      >
        <div class="orderTest-container">
          <el-form
            ref="form2"
            :model="form2"
            label-position="right"
            label-width="60px"
          >
            <el-form-item label="内容:" prop="">
              <el-input
                v-model="form2.content"
                type="textarea"
                style="height: 100px"
                rows="5"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div style="margin-top: ; text-align: right">
          <el-button type="primary" @click="handleAddContent">确定</el-button>
          <el-button @click="close2">取消</el-button>
        </div>
      </el-dialog>
      <edit ref="users"></edit>
    </el-dialog>
  </div>
</template>
<script>
  import {
    registerCustDetailData,
    registerCustDetailSubmit,
  } from '@/api/admin/userManagement'
  import Edit from './AddUsers'
  export default {
    name: 'CustDetail',
    components: { Edit },
    data() {
      return {
        id: '',
        row: null,
        dealer_id: null,
        loading: false,
        showDialog: false,
        showDialog2: false,
        form: {
          account_num: '',
          boss: '',
          dealer_id: '',
          dealer_name: '',
          end_time: '',
          mobile: '',
        },
        form2: {
          content: '',
        },
        list: [],
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.form = {
            account_num: '',
            boss: '',
            dealer_id: '',
            dealer_name: '',
            end_time: '',
            mobile: '',
          }
          this.list = []
        }
      },
      showDialog2(val) {
        if (!val) {
          this.form2.content = ''
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await registerCustDetailData({
          dealer_id: this.dealer_id,
          id: this.id,
        })
        this.form = data.dealer_info
        this.list = data.list
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      close2() {
        this.showDialog2 = false
      },
      // 编辑客户
      async handleEditCust(id) {
        this.$refs.users.showusers = true
        this.$refs.users.button = 2
        this.$refs.users.title = '编辑注册用户'
        await this.$refs.users.handlerprovince(this.row.province)
        await this.$refs.users.handlercity(this.row.city)
        await this.$refs.users.handlerinfo(this.row)
        this.$refs.edit.id = id
        this.$refs.edit.showDialog = true
      },
      handleAddRow() {
        this.showDialog2 = true
      },
      // 添加内容
      handleAddContent() {
        registerCustDetailSubmit({
          id: this.id,
          dealer_id: this.dealer_id,
          remark: this.form2.content,
        }).then((res) => {
          this.$message.success(res.msg)
          this.close2()
          this.fetchData()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
